import _ from "lodash";
import React from "react";
import Modal from "../Modal";
import AppContainer from "../AppContainer";
import Button from "../Button";

import { PAYMENT_ID } from "../../api-services/constants";
import { spreedlyTokenize } from "../../api-services/spreedlyAPI";
import { DEFAULT_ERROR, PAYMENT } from "../../utils/constants";
import { getParams, navigateTo, setParams } from "../../utils/location";
import PaymentAPIScriptLoader, {
    shouldLoadScript
} from "../PaymentAPIScriptLoader";

import ErrorMessage from "../ErrorMessage";
import CreditCardEmptyIcon from "../icons/CreditCardEmpty.svg";
import Loader from "../Loader";
import PaymentMethodInput from "../PaymentMethodInput";
import CreditCardDetails from "./CreditCardDetails";
import * as styles from "./index.module.scss";

export default class extends React.Component {
  fieldsRefs = {};

  state = {
    ...InitialFormFieldsState,
    shouldLoadPayment: shouldLoadScript(this.props.paymentTypeDetails),
    shouldClearCardInfo: false,
    addPaymentMethodModalOpen: false,
    isValidCreditCardInput: false
  };

  componentDidMount() {
    const { user, paymentTypeDetails } = this.props;

    if (user.loggedIn) {
      this.props.loadPaymentMethods(_.get(paymentTypeDetails, "paymentType"));
      this.props.resetAddPaymentMethod();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const backPath = getParams(location).backPath;
    if (
      !prevProps.user.addPaymentMethodsState.sent &&
      this.props.user.addPaymentMethodsState.sent
    ) {
      this.setState({
        ...this.state,
        shouldClearCardInfo: true,
        addPaymentMethodModalOpen: false,
      });
      const { paymentTypeDetails } = this.props;
      this.props.loadPaymentMethods(_.get(paymentTypeDetails, "paymentType"));
      this.props.resetAddPaymentMethod();
      if (_.get(this.props, "user.addPaymentMethodsState.data")) {
        this.props.setDefaultPaymentMethod(
          this.props.user.addPaymentMethodsState.data,
        );
      }
    }
    if (
      !prevProps.user.addPaymentMethodsState.error &&
      this.props.user.addPaymentMethodsState.error
    ) {
      this.openAddPaymentMethodModal();
    }
    if (!prevState.shouldClearCardInfo && this.state.shouldClearCardInfo) {
      this.resetCardInput();
      this.setState({ ...this.state, shouldClearCardInfo: false });
    }
  }

  onInputError = (fieldName) => (error) =>
    this.setState({ [`${fieldName}_ERROR`]: error });

  onInputValid = (fieldName) => (value) => {
    this.setState({ [`${fieldName}_ERROR`]: null, [fieldName]: value });
  };

  registerInput = (fieldName) => (ref) => {
    this.fieldsRefs = { ...this.fieldsRefs, [fieldName]: ref };
  };

  isValidField = (field) => !this.state[errorkey(field)] && !!this.state[field];

  resetCardInput = () => {
    _.map(FIELDS, (field) => {
      if (this.fieldsRefs[field] && this.fieldsRefs[field].clearInput) {
        this.fieldsRefs[field].clearInput();
      }
    });
  };

  isSpreedly = () =>
    _.includes(
      [PAYMENT_ID.SPREEDLY_TOAST, PAYMENT_ID.SPREEDLY_PURCHASE],
      this.props.paymentTypeDetails.paymentType,
    );

  isCardConnect = () => {
    return (
      _.get(this.props, "paymentTypeDetails.paymentType") ===
      PAYMENT_ID.CARD_CONNECT
    );
  };

  preTokenizeOrRecacheIfNeeded = () => {
    if (this.isSpreedly()) {
      return spreedlyTokenize({
        fullName: _.get(this.props.user, "userDetails.data.name"),
        ..._.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
          "month",
          "year",
          "zipCode",
        ]),
      });
    }
    if (this.isCardConnect() && document.getElementById("cardConnectToken")) {
      const token = _.get(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, "token");
      this.setState({
        [PAYMENT.PAYMENT_METHOD_INPUT]: {
          creditCard: {
            ..._.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
              "zipCode", "month", "year"
            ]),
          },
        },
      });

      return Promise.resolve({
        token,
        paymentMethod: {
          ..._.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
            "month", "year"
          ]),
          last_four_digits: token.substr(-4),
        },
      });
    }
    return Promise.resolve();
  };

  onSubmit = () => {
    if (this.state.shouldLoadPayment && !this.state.paymentLoaded) {
      console.error("not handled, zooz should be loaded but it failed to load");
      return;
    }
    const { user, paymentTypeDetails } = this.props;

    Promise.all(
      _.map(
        FIELDS,
        (field) =>
          new Promise((resolve) =>
            this.fieldsRefs[field].validate((err, value) => {
              this.setState(
                {
                  [field]: value,
                  [errorkey(field)]: err,
                },
                resolve,
              );
            }),
          ),
      ),
    ).then(() => {
      if (!this.isValidField(PAYMENT.PAYMENT_METHOD_INPUT)) {
        console.log("Invalid Form - payment details");
        this.setState({...this.state, isValidCreditCardInput:false});
        return;
      }

      this.preTokenizeOrRecacheIfNeeded()
        .then((tokenResponse) => {
          this.props.addPaymentMethod({
            creditCard: this.isSpreedly()
              ? _.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
                  "month",
                  "year",
                ])
              : this.isCardConnect()
              ? {
                  year: _.get(
                    this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard,
                    "year",
                  ),
                  month: _.get(
                    this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard,
                    "month",
                  ),
                  zipCode: _.get(
                    this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard,
                    "zipCode",
                  ),
                }
              : this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard,
            user,
            paymentTypeDetails,
            tokenResponse,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  removePaymentMethod = (token) => () => {
    console.log("REMOVING", token);
    console.log(this.props.paymentTypeDetails);
    this.props.removePaymentMethod(token, {
      paymentTypeIdentifier: _.get(
        this.props.paymentTypeDetails,
        "paymentType",
      ),
    });
  };

  getInputPropsFor = (inputId, refKey = "refEl") => ({
    [refKey]: this.registerInput(inputId),
    onValid: this.onInputValid(inputId),
    onError: this.onInputError(inputId),
    T: this.props.T,
  });

  onReturnClick = (backPath) => () => {
    const { location } = this.props;
    switch (backPath) {
      case "/payment":
        navigateTo("/payment", getParams(location));
        break;
      case "/my-account":
        navigateTo(setParams("/my-account", getParams(location)));
        break;
      case "/prepaid":
        navigateTo(setParams("/prepaid", getParams(location)));
        break;
      default:
        navigateTo(setParams("/", getParams(location)));
        break;
    }
  };

  openAddPaymentMethodModal = () =>
    this.setState({ addPaymentMethodModalOpen: true });
  closeAddPaymentMethodModal = () =>
    this.setState({ addPaymentMethodModalOpen: false });

  render() {
    const {
      T,
      location,
      user,
      appStyles = {},
      pageContext: { businessAppConfiguration },
      paymentTypeDetails,
    } = this.props;
    const { idRequired } = businessAppConfiguration || {};
    const { paymentMethods, defaultPaymentMethodToken } = user;

    const backPath = getParams(location).backPath;
    const { requireZipCode } = paymentTypeDetails || {};

    if (user.addPaymentMethodsState.sending) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <Loader appStyles={appStyles} classNames={styles.Loader} />
            <strong>{T("Adding payment method...")}</strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (user.removingPaymentMethods.sending) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <Loader appStyles={appStyles} classNames={styles.Loader} />
            <strong>{T("Removing payment method...")}</strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (_.get(user, "paymentMethods.loading")) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <Loader appStyles={appStyles} classNames={styles.Loader} />
            <strong>{T("Loading payment methods...")}</strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (_.get(user, "paymentMethods.error")) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <ErrorMessage appStyles={appStyles}>
            {T(user.paymentMethods.error.toString() || DEFAULT_ERROR)}
          </ErrorMessage>
        </AppContainer.Content>
      );
    }
    const filteredPaymentMethods = _.compact(_.get(paymentMethods, "data", []));

    return (
      <AppContainer.Content appStyles={appStyles} classNames={styles.Content}>
        {this.state.shouldLoadPayment && (
          <PaymentAPIScriptLoader
            paymentTypeDetails={paymentTypeDetails}
            onLoad={() => this.setState({ paymentLoaded: true })}
          />
        )}
        {_.isEmpty(filteredPaymentMethods) ? (
          <AppContainer.CenteredColumn>
            <div style={{ marginTop: 100, color: "#D9D9D9" }}>
              <CreditCardEmptyIcon
                style={{ color: appStyles.accentColor }}
                className={styles.CreditCardEmptyIcon}
              />
            </div>
            <div
              style={{
                color: appStyles.titleOnBackgroundColor,
                fontSize: "1.5rem",
                padding: 8,
              }}
            >
              {T("No payment methods")}
            </div>
            <Button
              appStyles={appStyles}
              style={{ flexGrow: 0, marginTop: 16 }}
              onClick={this.openAddPaymentMethodModal}
            >
              {T("Add a credit card")}
            </Button>
          </AppContainer.CenteredColumn>
        ) : (
          <div>
            {_.map(filteredPaymentMethods, (paymentMethod) => (
              <CreditCardDetails
                key={_.get(paymentMethod, "token")}
                T={T}
                appStyles={appStyles}
                paymentMethod={paymentMethod}
                removePaymentMethod={this.removePaymentMethod}
                defaultPaymentMethodToken={defaultPaymentMethodToken}
                backPath={backPath}
                setDefaultPaymentMethod={this.props.setDefaultPaymentMethod}
              />
            ))}
            <AppContainer.CenteredColumn>
              <Button
                appStyles={appStyles}
                style={{ flexGrow: 0, marginTop: 16 }}
                onClick={this.openAddPaymentMethodModal}
              >
                {T("Add a Credit Card")}
              </Button>
            </AppContainer.CenteredColumn>
          </div>
        )}
        <Modal
          open={this.state.addPaymentMethodModalOpen}
          onClose={this.closeAddPaymentMethodModal}
          focusTrapped
          style={{ width: "100%" }}
          appStyles={appStyles}
        >
          {user.addPaymentMethodsState.error && (
            <ErrorMessage appStyles={appStyles}>
              {T(user.addPaymentMethodsState.error)}
            </ErrorMessage>
          )}
          <PaymentMethodInput
            key={1}
            T={T}
            appStyles={appStyles}
            getInputPropsFor={this.getInputPropsFor}
            changeTargetURL={setParams("/payment-methods", {
              ...this.params,
              backPath: "/payment",
            })}
            idRequired={idRequired}
            requireZipCode={requireZipCode}
            ref={this.registerInput(PAYMENT.PAYMENT_METHOD_INPUT)}
            paymentTypeDetails={paymentTypeDetails}
            paymentScriptLoaded={this.state.paymentLoaded}
            updateValidState={(valid) => {
              this.setState({...this.state, isValidCreditCardInput:valid});
            }}
          />
         
          <Button
            appStyles={appStyles}
            centered
            marginTop
            onClick={this.onSubmit}
            style={{ marginRight: 10, marginLeft: 10 }}
          >
            {T("Add Card")}
          </Button>
        </Modal>
        {_.isEqual(backPath, "/payment") && (
          <AppContainer.Footer classNames={styles.HideOnDesktop}>
            <AppContainer.Footer.Button
              onClick={this.onReturnClick(backPath)}
              appStyles={appStyles}
            >
              {T("Return to checkout")}
            </AppContainer.Footer.Button>
          </AppContainer.Footer>
        )}
      </AppContainer.Content>
    );
  }
}

const FIELDS = [PAYMENT.PAYMENT_METHOD_INPUT];

const errorkey = (key) => `${key}_ERROR`;

const InitialFormFieldsState = FIELDS.reduce(
  (o, key) => ({ ...o, [key]: null, [errorkey(key)]: null }),
  {},
);
