import React from "react";
import PaymentMethodsView from "../components/PaymentMethodsView";
import withTranslation from "../hocs/withTranslation";
import { connect } from "react-redux";
import { addPaymentMethod } from "../store/order/actions";
import { includes } from "lodash";
import {
  loadPaymentMethods,
  removePaymentMethod,
  setDefaultPaymentMethod,
  resetAddPaymentMethod,
} from "../store/user/actions";
import {
  getCurrentPaymentMethodDetails,
  getDefaultPaymentMethodDetails,
} from "../store/selectors";
import { getAppStyles } from "../store/selectors/app-styles";

const PaymentMethodsPage = (props) => <PaymentMethodsView {...props} />;

const shouldUseDefaultPaymentMethod = (state, props) => {
  const { location } = props;
  const { order } = state;
  return includes(location.search, "gift-card") || !order.branchId;
};

const mapStateToProps = (state, props) => {
  const { app, user, header, order, pageContext } = state;

  return {
    app,
    order,
    user,
    header,
    appStyles: getAppStyles(state, props),
    paymentTypeDetails: shouldUseDefaultPaymentMethod(state, props)
      ? getDefaultPaymentMethodDetails(state, props)
      : getCurrentPaymentMethodDetails(state, props),
  };
};
const mapDispatchToProps = (dispatch, props) => {
  const {
    pageContext: { business },
  } = props;

  const currency = business.openChargeCard
    ? business.openChargeCard.price.currency
    : 0;

  return {
    loadPaymentMethods: (paymentTypeIdentifier) =>
      dispatch(loadPaymentMethods(paymentTypeIdentifier, currency)),
    resetAddPaymentMethod: () => dispatch(resetAddPaymentMethod()),
    setDefaultPaymentMethod: (token) =>
      dispatch(setDefaultPaymentMethod(token)),
    removePaymentMethod: (paymentMethodToken, { paymentTypeIdentifier }) =>
      dispatch(
        removePaymentMethod(paymentMethodToken, {
          paymentTypeIdentifier,
          currency,
        }),
      ),
    addPaymentMethod: (params) =>
      dispatch(addPaymentMethod({ ...params, currency })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(PaymentMethodsPage));
